<template>
  <div>
    <div>
      <div class="right_header">
        <Row :gutter="16">
          <Col span="5">
            <span>姓名或手机号码</span>
            <Input v-model="params.userName" />
          </Col>
          <Col span="4">
            <span>职务名称</span>
            <Select v-model="params.post"  style="position: relative" :transfer="transfer">
                <Option value="">全部</Option>
                <Option v-for="(item,index) in positionList" :key="index" :value="item.value">{{item.name}}</Option>
            </Select>
          </Col>
          <Col span="7">
            <div
              class="button_style"
              style=" position: relative; top: 50%"
              @click="search()"
            >
              查询
            </div>
            <div
              class="button_style"
              @click="addUser()"
              style="
                margin-left: 16px;
                cursor: pointer;
                position: relative;
                top: 50%;
                background-color: rgb(253, 167, 54);
              "
            >
              新增账号
            </div>
          </Col>
        </Row>
      </div>
      <div class="right_real_content">
        <Table border :columns="columns12" :data="data7" :disabled-hover="true">
          <template slot-scope="{ row }" slot="name">
            <strong>{{ row.name }}</strong>
          </template>
          <template slot-scope="{ row, list }" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 5px; color: rgb(24,144,255)"
              @click="update(row)"
              >修改</Button
            >
            <span class="operation_span" style="color: rgba(220, 220, 220, 1)"
              >|</span
            >
            <Button
              type="error"
              size="small"
              @click="remove(row.id)"
              style="color: rgb(24,144,255)"
              >删除</Button
            >
            <span
              v-if="row.accountType == '0'"
              class="operation_span"
              style="color: rgba(220, 220, 220, 1)"
              >|</span
            >
            <Button
              v-if="row.accountType == '0'"
              type="error"
              size="small"
              @click="setZhu(row.id)"
              style="color: rgb(54, 194, 207)"
              >设为主账号</Button
            >
          </template>
        </Table>
        <Page
          :total="total"
          show-elevator
          style="margin-top: 50px; float: right; margin-bottom: 20px"
          @on-change="changePage"
        />
      </div>
    </div>
    <Modal
      v-model="userModal"
      ok-text="提交"
      cancel-text="取消"
      width="800"
      title="新增账号"
    >
      <div
        style="margin-top: 40px; margin-bottom: 40px; border-bottom: none"
        class="div_center"
      >
        <Form :model="userForm" :label-width="100">
          <FormItem label="手机号">
            <Input
              v-model="userForm.userName"
              placeholder="请输入手机号码"
            ></Input>
          </FormItem>
          <FormItem label="设置登录密码">
            <Input
              v-model="userForm.password"
              placeholder="设置登录密码"
            ></Input>
          </FormItem>
          <FormItem label="设置真实姓名">
            <Input
              v-model="userForm.compellation"
              placeholder="设置真实姓名"
            ></Input>
          </FormItem>
          <FormItem label="设置职务">
            <Input v-model="userForm.post" placeholder="设置职务"></Input>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <div
          class="button_style"
          style="margin-left: 26px; width: 80px; position: relative; top: 50%"
          @click="submitAdd()"
        >
          提交
        </div>
        <div
          class="button_style"
          style="
            margin-left: 26px;
            width: 80px;
            position: relative;
            top: 50%;
            background-color: #fda736;
          "
          @click="addCancel()"
        >
          取消
        </div>
      </div>
    </Modal>
    <Modal
      v-model="userUpdateModal"
      ok-text="提交"
      cancel-text="取消"
      width="800"
      title="修改账号"
    >
      <div
        style="margin-top: 40px; margin-bottom: 40px; border-bottom: none"
        class="div_center"
      >
        <Form :model="updateUserForm" :label-width="100">
          <FormItem label="手机号">
            <Input
              v-model="updateUserForm.username"
              placeholder="请输入手机号码"
            ></Input>
          </FormItem>
          <FormItem label="设置登录密码">
            <Input
              v-model="updateUserForm.password"
              placeholder="设置登录密码(不填则默认原密码)"
            ></Input>
          </FormItem>
          <FormItem label="设置真实姓名">
            <Input
              v-model="updateUserForm.compellation"
              placeholder="设置真实姓名"
            ></Input>
          </FormItem>
          <FormItem label="设置职务">
            <Input v-model="updateUserForm.post" placeholder="设置职务"></Input>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <div
          class="button_style"
          style="margin-left: 26px; width: 80px; position: relative; top: 50%"
          @click="submitUpdate()"
        >
          提交
        </div>
        <div
          class="button_style"
          style="
            margin-left: 26px;
            width: 80px;
            position: relative;
            top: 50%;
            background-color: #fda736;
          "
          @click="updateCancel()"
        >
          取消
        </div>
      </div>
    </Modal>
    <Modal
      v-model="deleteModal"
      title="删除账号"
      width="400"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
          >
            <div style="margin-bottom: 30px">
              <span>您确定删除该账号信息？</span>
            </div>
            <div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                @click="delCancel()"
              >
                我再想想
              </div>
              <div
                class="button_style"
                style="
                  cursor: pointer;
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                @click="confirmDel()"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="setMainModal"
      title="设为主账号"
      width="400"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
          >
            <div style="margin-bottom: 30px">
              <span>您确定将该账号设为主账号吗？</span>
            </div>
            <div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                @click="delSetMain()"
              >
                我再想想
              </div>
              <div
                class="button_style"
                style="
                  cursor: pointer;
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                @click="confirmSetMain()"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getAccountList,
  delAccount,
  getPosition,
  setMain,
  addAccount,
  updateAccount,
} from "@/plugins/api/EnterpriseManagementCenter";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
} from "@/plugins/tools/util";
import { mapMutations, mapGetters } from "vuex";
import {errorToast} from "../../../plugins/tools/util";
export default {
  name: "AccountManagement",
  mounted() {
    this.getAccount();
    getPosition().then(res=>{
      if(res.code ==0){
        for(let i=0;i<res.result.length;i++){

          if(res.result[i] != null){
            this.positionList.push(res.result[i])
          }
        }
      }
    })
  },
  data() {
    return {
      transfer:true,
      positionList:[],
      footHide: false,
      userUpdateModal: false,
      userModal: false,
      deleteModal: false,
      setMainModal: false,
      nameOrNumber: "",
      jobName: "",
      data7: [],  //table数据
      deleteId: "",
      setMainId: "",
      total: 10,
      columns12: [
        {
          type: "list.vue",
          key: "序号",
          align: "center",
          width: 60,
        },
        {
          title: "手机号码",
          key: "username",
          align: "center",
        },
        {
          title: "姓名",
          key: "compellation",
          align: "center",
        },
        {
          title: "职务",
          key: "post",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createdTime",
          align: "center",
        },
        {
          title: "操作",
          width: 250,
          slot: "action",
          align: "center",
        },
      ],
      userForm: {
        password: "",
        userName: "",
        compellation: "",
        post: "",
      },
      userFormSecond: {
        password: "",
        userName: "",
        compellation: "",
        post: "",
      },
      updateUserForm: {},
      params: {
        userName: "",
        post: "",
        pageSize: 10,
        pageNum: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      token: "userStore/token",
      userName: "userStore/userName",
      userId: "userStore/userId",
      enterpriseState: "userStore/enterpriseState",
      mbrMemberId:"userStore/mbrMemberId",
    }),
  },
  methods: {
    // 查询

    async search() {
      this.params.mbrMemberId = this.mbrMemberId;
      const json = await getAccountList(this.params);
      if (json.code === 0) {
        this.data7 = json.result.list;
        this.total = json.result.total;
        // this.$parent.changeHeight();
        this.params.userName = ""
        this.params.post = ""
      }else{
        errorToast(json.message)
      }

    },

    // 翻页
    async changePage(index) {
      this.params.pageNum = index;
      const json = await getAccountList(this.params);
      if (json.code === 0) {
        this.data7 = json.result.list;
        this.total = json.result.total;
        // this.$parent.changeHeight();
      }
    },

    //查询账号
    async getAccount() {
      this.params.mbrMemberId = this.mbrMemberId;
      const json = await getAccountList(this.params);
      if (json.code === 0) {
        this.data7 = json.result.list;
        // this.$parent.changeHeight();
      }
    },

    //更新账号
    update(row) {
      let { username, compellation, password, post, id } = row;
      this.updateUserForm = { username, compellation, password, post, id };
      this.userUpdateModal = true;
    },
    updateCancel() {
      this.userUpdateModal = false;
    },
    async submitUpdate() {
      if (this.updateUserForm.password) {
        if (!isCorrectPassword(this.updateUserForm.password)) {
          warnToast("请输入8-16位数字字母组合密码");
          return;
        }
      }

      if (!isPhoneNumber(this.updateUserForm.username)) {
        warnToast("请正确的手机号");
        return;
      }
      let data = this.updateUserForm;
      const json = await updateAccount(data);
      if (json.code === 0) {
        successToast("更新成功");
        this.getAccount();
      } else {
        warnToast(json.message || "修改失败");
      }
      this.userUpdateModal = false;
    },

    // 添加主账号
    addUser() {
      this.userModal = true;
    },
    addCancel() {
      this.userModal = false;
    },
    async submitAdd() {
      if (!isCorrectPassword(this.userForm.password)) {
        warnToast("请输入8-16位数字字母组合密码");
        return;
      }
      if (!isPhoneNumber(this.userForm.userName)) {
        warnToast("请正确的手机号");
        return;
      }
      let data = this.userForm;
      const json = await addAccount(data);
      if (json.code === 0) {
        successToast("添加成功");
        this.userForm = this.userFormSecond;
        this.userModal = false;
        this.getAccount();
      } else {
        warnToast(json.message || "添加失败");
      }
    },

    // 删除账号
    remove(id) {
      this.deleteModal = true;
      this.deleteId = id;
    },
    delCancel() {
      this.deleteModal = false;
    },
    async confirmDel() {
      let params = {
        id: this.deleteId + "",
      };
      let json = await delAccount(JSON.stringify(params));
      if (json.code === 0) {
        this.getAccount();
        successToast("删除成功");
      } else {
        warnToast(json.message || "修改失败");
      }

      this.deleteModal = false;
    },

    // 设为主账号
    setZhu(id) {
      this.setMainModal = true;
      this.setMainId = id;
    },
    delSetMain() {
      this.setMainModal = false;
    },
    async confirmSetMain() {
      let data = {
        id: this.setMainId,
        mbrMemberId: this.mbrMemberId,
      };
      let json = await setMain(data);
      if (json.code === 0) {
        successToast("设置成功");
        this.setMainModal = false;
        this.getAccount();
      } else {
        warnToast(json.message || "设置失败");
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: #f3fcfc !important;
}
/deep/ .ivu-modal-header-inner {
  text-align: center;
  background-color: rgb(245, 246, 250) !important;
}

/deep/ .ivu-modal-header {
  background-color: rgb(245, 246, 250) !important;
}
/deep/ .ivu-modal-footer {
  display: none !important;
}
/deep/ .ivu-rate-text {
  display: none;
}
/deep/ td {
  border-right: none !important;
}

/deep/ .ivu-table-row-hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1) !important;
}
.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;
  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  display: inline-block;
  background-color: rgb(54, 194, 207);
  width: 100px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}
</style>
