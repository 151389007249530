import {get,post,del,put} from '../http/http'
import baseUrl, {patentUrl, qxtUrl} from "../http/baseUrl";

//企业看板接口
export const getSpectaculars = (params)=>{
  return get(`${baseUrl}/v1/spectaculars/total`,params);
}

// 系统通知
export const getSystem = (params) => {
  return get(`${baseUrl}/v1/sysmsg/user`,params);
}
// 账号管理
// 账号列表
export const getAccountList=(params)=> {
  return get(`${baseUrl}/v1/account/allList`,params);
}

export const getAgenda = (params) => {
  return get(`${baseUrl}/v1/spectaculars/agenda`,params);
}

//删除账号
export const delAccount = (params) => {
  return del(`${baseUrl}/v1/account/deleteAccount`,params);
}

//设为主账号
export const setMain = (data) => {
  return put(`${baseUrl}/v1/account/setType`,data);
}

//添加主账号
export const addAccount = (data) => {
  return post(`${baseUrl}/v1/account/saveAccount`,data)
}

//修改账号
export const updateAccount = (data) => {
  return put(`${baseUrl}/v1/account/updateAccount`,data);
}


//企业咨询管理
//企业咨询列表
export const getConsultingList=(params)=> {
  return get(`${baseUrl}/v1/goods/comment`,params);
}

//企业信息管理
export const getEnterpriseInfo=(params) => {
  return get(`${baseUrl}/v1/enterprise/info`,params)
}


//专利订单管理
//订单列表
export const getPatentOrderList=(params) => {
  return get(`${baseUrl}/v1/order/allList`,params)
}


//删除订单
export const delPatentOrder=(params) => {
  return del(`${baseUrl}/v1/order/deleteOrder`,params)
}


//取消订单
export const removePatentOrder=(params) => {
  return del(`${baseUrl}/v1/order/cancelOrder`,params)
}


// 查看订单
export const getOrder = (params) => {
  return get(`${baseUrl}/v1/order`,params)
}

// 评价
export const saveComment =(params) => {
  return post(`${baseUrl}/v1/orderEvaluate/save`,params)
}


// 获取职务

export const getPosition=(params) => {
  return get(`${baseUrl}/v1/account/postList`,params);
}

export const getWordCloud=(data) => {
  return get(`${qxtUrl}/enterprise/request/tag`,data);
}

export const uploadVoucher =(data) => {
  return put(`${baseUrl}/v1/order/uploadVoucher`,data)
}

// /gateway/achievements/report    入参：enterpriseId 企业ID、pageSize=1、pageNum=1 科研成果项  total
// /gateway/expert/report    入参：enterpriseId 企业ID、pageSize=1、pageNum=1 科研专家项  total
// /gateway/patent/report    入参：enterpriseId 企业ID、pageSize=1、pageNum=1 符合企业的专利项  total

export const getScienceResult = (data) => {
  return get(`${qxtUrl}/gateway/achievements/report`,data)
}
export const getScienceExpert= (data) => {
  return get(`${qxtUrl}/gateway/expert/report`,data)
}
export const getSciencePatent = (data) => {
  return get(`${qxtUrl}/gateway/patent/report`,data)
}